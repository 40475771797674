import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AdminCategories from "../admin-panel/categories/AdminCategories";
import { Login } from "../admin-panel/login/AdminLogin";
import ParentCategories from "../admin-panel/categories/ParentCategories";
import AddAttributes from "../admin-panel/categories/AddAttributes";
import SubCategories from "../admin-panel/categories/SubCategories";
import ChildCategories from "../admin-panel/categories/ChildCategories";
import SideBar from "../admin-panel/SideBar";
import Layout from "./Layout";
import ApproveSellerList from "../admin-panel/approve-sellerlist/ApproveSellerList";
import SellerVerification from "../admin-panel/seller-verification/SellerVerification";
import AllProduct from "../admin-panel/all-product-list/AllProduct";
import ReviewProduct from "../admin-panel/review-product/ReviewProduct";
import ApproveProduct from "../admin-panel/approve-product/ApproveProduct";
import AddChildAttribute from "../admin-panel/categories/AddChildAttribute";
import AwardList from "../admin-panel/award-list/AwardList";
import CountryList from "../admin-panel/country-list/CountryList";
import Orders from "../admin-panel/orders/Orders";
import Coupons from "../admin-panel/coupons/Coupons";
import Sellerdetails from "../admin-panel/approve-sellerlist/Sellerdetails";
import Error from "../admin-panel/Error";
import { Dashboard } from "../admin-panel/dashboard/Dashboard";
import Customers from "../admin-panel/customers/Customers";
import CustomersDetails from "../admin-panel/customers/CustomersDetails";
// Cms Section
import FaqSection from "../admin-panel/cms/FaqSection";
import Privacy from "../admin-panel/cms/Privacy";
import Terms from "../admin-panel/cms/Terms";
import Refund from "../admin-panel/cms/Refund";
import { Brands } from "../admin-panel/brands/Brands";
import ManageGst from "../admin-panel/gst/ManageGst";
import Preview from "../admin-panel/cms/content-manager/preview/Preview";
import ReviewRating from "../admin-panel/review-rating/ReviewRating";
import Banners from "../admin-panel/starup-cms/Banners";
//Manage staff
import CategoryHeadDashboard from "../managerdashboard/ch/CategoryHeadDashboard";
import CategoryHeadAssignSeller from "../managerdashboard/ch/CategoryHeadAssignSeller";
import AssociateSellerWithCm from "../managerdashboard/ch/AssociateSellerWithCm";
import ManagerDashboard from "../managerdashboard/cm/ManagerDashboard";
import { CategoryManagerLogin } from "../admin-panel/login/CategoryManagerLogin";
import ManageStaff from "../admin-panel/manage-staff/ManageStaff";
import ManageRoles from "../admin-panel/manage-staff/ManageRoles";
import SignupForm from "../managerdashboard/cm/SellerOnboard";
import DesignationList from "../admin-panel/manage-staff/DesignationList";
import B2bVerification from "../admin-panel/b2b/B2bVerification";
import Profile from "../managerdashboard/cm/Profile";
import QAADashboard from "../managerdashboard/qaa/QA";
import QaProfile from "../managerdashboard/qaa/QaProfile";
import PendingSeller from "../managerdashboard/qaa/PendingSeller";
import ChProfile from "../managerdashboard/ch/Chprofile";
import Qapassword from "../managerdashboard/qaa/Qapassword";
import DeclinedSeller from "../managerdashboard/cm/DeclinedSeller";
import CmPassword from "../managerdashboard/cm/Cmpassword";
import DeclineSellers from "../managerdashboard/qaa/DeclineSeller";
import MMDash from "../managerdashboard/mm/MMdash";
import MHDash from "../managerdashboard/mh/MHdash";
import ChPassword from "../managerdashboard/ch/Chpassword";
import StaffLayout from "./StaffLayout.jsx";
import MHProfile from "../managerdashboard/mh/MHProfile.jsx";
import MMProfile from "../managerdashboard/mm/MMProfile.jsx";
import MHPassword from "../managerdashboard/mh/MHpassword.jsx";
import MMPassword from "../managerdashboard/mm/MMpassword.jsx";

import ManageCancellation from "../admin-panel/manage-cancellation/ManageCancellation.jsx";

import AssociateBrandsWithMm from "../managerdashboard/mh/AssociateBrandsWithMm.jsx";
import MHAssignSeller from "../managerdashboard/mh/MHAssignSeller.jsx";
import AssociateBrand from "../managerdashboard/mm/AssociateBrand.jsx";
import AssociateBrandsProducts from "../managerdashboard/mh/AssociateBrandsProducts.jsx";

import Distributor from "../admin-panel/distributor/Distributor.jsx";
import Hotdeal from "../admin-panel/manage/hotDeal/Hotdeal.jsx";
import ContactList from "../admin-panel/contact-list/ContactList.jsx";
import CompanyAddress from "../admin-panel/cms/address/CompanyAddress.jsx";
import UtilityList from "../admin-panel/settings/utility/UtilityList.jsx";
import BrandBanner from "../admin-panel/brand-banner/BrandBanner.jsx";
import BlogPage from "../admin-panel/blog-category/BlogPage.jsx";
import BlogPost from "../managerdashboard/content-writer/BlogPost.jsx";
import AddSellerBrand from "../managerdashboard/ch/AddSellerBrand.jsx";

import ContentHeadDashboard from "../managerdashboard/content-head/ContentHeadDashboard.jsx";
import ContentWriterDashboard from "../managerdashboard/content-writer/ContentWriterDashboard.jsx";
import BlogList from "../managerdashboard/content-head/BlogList.jsx";
import ContentWriters from "../managerdashboard/content-head/ContentWriters.jsx";
import ApprovedBlogList from "../managerdashboard/content-head/ApprovedBlogList.jsx";
import AssociateBlogWithCw from "../managerdashboard/content-head/AssociateBlogWithCw.jsx";
import Blogpreview from "../managerdashboard/content-head/BlogPreview.jsx";

import BrandList from "../managerdashboard/ch/BrandList.jsx";

import CompletedTask from "../managerdashboard/content-writer/completed/CompletedTask.jsx";
import PendingTask from "../managerdashboard/content-writer/pending/PendingTask.jsx";
import DraftTask from "../managerdashboard/content-writer/draft/DraftTask.jsx";
import PostBlog from "../managerdashboard/content-writer/PostBlog.jsx";
import BlogEdit from "../managerdashboard/content-head/BlogEdit.jsx";
import ImageUpload from "../managerdashboard/content-head/ImageUpload.jsx";
import DeclinedTask from "../managerdashboard/content-writer/declined/DeclinedTask.jsx";
import ReviewedTask from "../managerdashboard/content-writer/reviewed/ReviewedTask.jsx";
import BlogPreview from "../managerdashboard/content-writer/BlogPreview.jsx";
import PrivacyModal from "../admin-panel/cms/PrivacyModal.jsx";
import ShippingPolicy from "../admin-panel/cms/ShippingPolicy.jsx";
import WarrantyPolicy from "../admin-panel/cms/WarrantyPolicy.jsx";
import ChildCategoriesBanner from "../admin-panel/child-banner/ChildCategories.jsx";
import SubCategoriesBanner from "../admin-panel/sub-banner/SubCategories.jsx";
import ParentCategoriesBanner from "../admin-panel/parent-banner/ParentCategories.jsx";
import CwProfile from "../managerdashboard/content-writer/profile/CwProfile.jsx";
import CwProfileDetails from "../managerdashboard/content-writer/profile/CwProfileDetails.jsx";
import PaymentDetails from "../managerdashboard/content-head/PaymentDetails.jsx";
import Preorderlist from "../admin-panel/PreorderList/Preorderlist.jsx";
import AdditionalCoupon from "../admin-panel/coupons/addition-coupon/AdditionalCoupon.jsx";
import Application from "../admin-panel/application/Application.jsx";

const PreserveLocation = ({ children }) => {
  const location = useLocation();
  return children(location);
};
const MainRoutes = () => {
  const handleLogout = () => {
    localStorage.setItem("isLogin", false);
    window.location.href = "/";
  };
  const ProtectedRoute = ({ element: Element, ...rest }) => {
    const [isLogin, setIsLogin] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      let storedIsLogin = localStorage.getItem("isLogin");
      if (!storedIsLogin) {
        navigate("/");
      }
      setIsLogin(storedIsLogin);
    }, [navigate]);
    return (
      isLogin && (
        <Layout
          sidebar={<SideBar />}
          content={<Element />}
          handleLogout={handleLogout}
        />
      )
    );
  };

  const ProtectedStaffRoute = ({ element: Element, ...rest }) => {
    const [isStaffLogin, setIsStaffLogin] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      let storedIsLogin = localStorage.getItem("isStaffLogin");
      if (!storedIsLogin) {
        navigate("/stafflogin");
      }
      setIsStaffLogin(storedIsLogin);
    }, [navigate]);
    return (
      isStaffLogin && (
        <StaffLayout content={<Element />} handleLogout={handleLogout} />
      )
    );
  };
  return (
    <Router>
      <PreserveLocation>
        {(location) => (
          <Routes location={location}>
            <Route path="/" element={<Login />} />
            <Route
              path="/manage-staff"
              element={<ProtectedRoute element={ManageStaff} />}
            />
            <Route path="/stafflogin" element={<CategoryManagerLogin />} />
            <Route path="/onboard-seller" element={<SignupForm />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute element={Dashboard} />}
            />
            <Route
              path="/category"
              element={<ProtectedRoute element={AdminCategories} />}
            />
            <Route
              path="/category/parentcategory"
              element={<ProtectedRoute element={ParentCategories} />}
            />
            <Route
              path="/category/subcategory"
              element={<ProtectedRoute element={SubCategories} />}
            />
            <Route
              path="/category/childcategory"
              element={<ProtectedRoute element={ChildCategories} />}
            />
            <Route
              path="/category/attributes"
              element={<ProtectedRoute element={AddAttributes} />}
            />
            <Route
              path="/seller/sellerlist"
              element={<ProtectedRoute element={ApproveSellerList} />}
            />
            <Route
              path="/seller/sellerverification"
              element={<ProtectedRoute element={SellerVerification} />}
            />
            <Route
              path="/distributorship"
              element={<ProtectedRoute element={Distributor} />}
            />
            <Route
              path="/allproduct"
              element={<ProtectedRoute element={AllProduct} />}
            />
            <Route
              path="/utility"
              element={<ProtectedRoute element={UtilityList} />}
            />
            <Route
              path="/add-home-banner-1"
              element={<ProtectedRoute element={BrandBanner} />}
            />
            <Route
              path="/child-banner"
              element={<ProtectedRoute element={ChildCategoriesBanner} />}
            />
            <Route
              path="/sub-banner"
              element={<ProtectedRoute element={SubCategoriesBanner} />}
            />
            <Route
              path="/parent-banner"
              element={<ProtectedRoute element={ParentCategoriesBanner} />}
            />
            <Route
              path="/reviewproduct"
              element={<ProtectedRoute element={ReviewProduct} />}
            />
            <Route
              path="/approveproduct"
              element={<ProtectedRoute element={ApproveProduct} />}
            />
            <Route
              path="/orders"
              element={<ProtectedRoute element={Orders} />}
            />
            <Route
              path="/preorders"
              element={<ProtectedRoute element={Preorderlist} />}
            />
            <Route
              path="/category/:attribute"
              element={<ProtectedRoute element={AddChildAttribute} />}
            />
            <Route
              path="/awardlist"
              element={<ProtectedRoute element={AwardList} />}
            />
            <Route
              path="/manage-cancellation"
              element={<ProtectedRoute element={ManageCancellation} />}
            />
            <Route
              path="/countrylist"
              element={<ProtectedRoute element={CountryList} />}
            />
            <Route
              path="/address"
              element={<ProtectedRoute element={CompanyAddress} />}
            />
            <Route
              path="/contactlist"
              element={<ProtectedRoute element={ContactList} />}
            />
            <Route
              path="/coupons"
              element={<ProtectedRoute element={Coupons} />}
            />
            <Route
              path="/addition-coupons"
              element={<ProtectedRoute element={AdditionalCoupon} />}
            />
            <Route
              path="/customers"
              element={<ProtectedRoute element={Customers} />}
            />
            <Route
              path="/customers/:id"
              element={<ProtectedRoute element={CustomersDetails} />}
            />
            <Route
              path="/sellerdetails/:id"
              element={<ProtectedRoute element={Sellerdetails} />}
            />
            <Route path="*" element={<Error />} />
            {/* //Cms Section */}
            <Route
              path="/cms/faqs"
              element={<ProtectedRoute element={FaqSection} />}
            />
            <Route
              path="/cms/privacy-policy"
              element={<ProtectedRoute element={Privacy} />}
            />

            <Route
              path="/cms/shipping-policy"
              element={<ProtectedRoute element={ShippingPolicy} />}
            />
            <Route
              path="/cms/warranty-policy"
              element={<ProtectedRoute element={WarrantyPolicy} />}
            />

            <Route
              path="/cms/terms-conditions"
              element={<ProtectedRoute element={Terms} />}
            />
            <Route
              path="/cms/refunds-cancellations"
              element={<ProtectedRoute element={Refund} />}
            />
            <Route
              path="/brands"
              element={<ProtectedRoute element={Brands} />}
            />
            <Route
              path="/application"
              element={<ProtectedRoute element={Application} />}
            />
            <Route
              path="/b2buser"
              element={<ProtectedRoute element={B2bVerification} />}
            />
            {/* A + content manager preview */}
            <Route
              path="/preview-content-manager"
              element={<ProtectedRoute element={Preview} />}
            />
            <Route
              path="/ManageGst"
              element={<ProtectedRoute element={ManageGst} />}
            />
            {/* // Review Rationg */}
            <Route
              path="/review-rating"
              element={<ProtectedRoute element={ReviewRating} />}
            />
            {/* // Blog Category */}
            <Route
              path="/blog-category"
              element={<ProtectedRoute element={BlogPage} />}
            />
            {/* // Blog Category */}
            <Route
              path="/blog-post"
              element={<ProtectedRoute element={BlogPost} />}
            />
            {/* startup banner */}
            <Route
              path="/startup-cms"
              element={<ProtectedRoute element={Banners} />}
            />
            <Route
              path="/hot-deal"
              element={<ProtectedRoute element={Hotdeal} />}
            />
            {/* Manage Staff */}
            <Route
              path="/category-manager-dashboard"
              element={<ProtectedStaffRoute element={ManagerDashboard} />}
            />
            <Route
              path="/content-head-dashboard"
              element={<ProtectedStaffRoute element={ContentHeadDashboard} />}
            />
            <Route
              path="/content-head-dashboard/content-writers"
              element={<ProtectedStaffRoute element={ContentWriters} />}
            />
            <Route
              path="/content-head/content-writers-payment/:id"
              element={<ProtectedStaffRoute element={PaymentDetails} />}
            />
            <Route
              path="/content-head-dashboard/associate-blog/:id/:name"
              element={<ProtectedStaffRoute element={AssociateBlogWithCw} />}
            />
            <Route
              path="/content-head-dashboard/blog-list"
              element={<ProtectedStaffRoute element={BlogList} />}
            />
            <Route
              path="/content-head-dashboard/add-blog-category"
              element={<ProtectedStaffRoute element={BlogPage} />}
            />
            <Route
              path="/content-head-dashboard/blog-preview/:id"
              element={<ProtectedStaffRoute element={Blogpreview} />}
            />
            <Route
              path="/content-head-dashboard/approved-blogs"
              element={<ProtectedStaffRoute element={ApprovedBlogList} />}
            />
            <Route
              path="/content-head-dashboard/blog-edit/:id"
              element={<ProtectedStaffRoute element={BlogEdit} />}
            />
            <Route
              path="/content-head-dashboard/image-upload"
              element={<ProtectedStaffRoute element={ImageUpload} />}
            />
            <Route
              path="/content-writer-dashboard/post/:id"
              element={<ProtectedStaffRoute element={PostBlog} />}
            />
            <Route
              path="/content-writer-dashboard"
              element={<ProtectedStaffRoute element={ContentWriterDashboard} />}
            />
            <Route
              path="/content-writer-dashboard/blog-category"
              element={<ProtectedStaffRoute element={BlogPage} />}
            />
            {/* // Blog Category */}
            <Route
              path="/content-writer-dashboard/blog-post/:id"
              element={<ProtectedStaffRoute element={BlogPost} />}
            />
            <Route
              path="/content-writer-dashboard/post/:id"
              element={<ProtectedStaffRoute element={PostBlog} />}
            />

            <Route
              path="/content-writer/completed-task"
              element={<ProtectedStaffRoute element={CompletedTask} />}
            />
            <Route
              path="/content-writer/pending-task"
              element={<ProtectedStaffRoute element={PendingTask} />}
            />
            <Route
              path="/content-writer/draft-task"
              element={<ProtectedStaffRoute element={DraftTask} />}
            />
            <Route
              path="/content-writer/declined-task"
              element={<ProtectedStaffRoute element={DeclinedTask} />}
            />
            <Route
              path="/content-writer/reviewed-task"
              element={<ProtectedStaffRoute element={ReviewedTask} />}
            />

            <Route
              path="/content-writer/blog-preview/:id"
              element={<ProtectedStaffRoute element={BlogPreview} />}
            />
            <Route
              path="/content-writer/profile"
              element={<ProtectedStaffRoute element={CwProfile} />}
            />
            <Route
              path="/content-writer/profile-details"
              element={<ProtectedStaffRoute element={CwProfileDetails} />}
            />
            <Route
              path="/category-head-dashboard"
              element={<ProtectedStaffRoute element={CategoryHeadDashboard} />}
            />

            <Route
              path="/category-head-dashboard/associate-seller/:id"
              element={<ProtectedStaffRoute element={AssociateSellerWithCm} />}
            />
            <Route
              path="/marketing-head-dashboard/associate-brands/:id"
              element={<ProtectedStaffRoute element={AssociateBrandsWithMm} />}
            />
            <Route
              path="/marketing-head-dashboard/associate-brands/products/:id"
              element={
                <ProtectedStaffRoute element={AssociateBrandsProducts} />
              }
            />
            <Route
              path="/category-head-profile"
              element={<ProtectedStaffRoute element={ChProfile} />}
            />
            <Route
              path="/category-head-password-change"
              element={<ProtectedStaffRoute element={ChPassword} />}
            />

            <Route
              path="/category-head-assign-seller"
              element={
                <ProtectedStaffRoute element={CategoryHeadAssignSeller} />
              }
            />
            <Route
              path="/ch-add-seller"
              element={<ProtectedStaffRoute element={AddSellerBrand} />}
            />
            <Route
              path="/ch-brand-list"
              element={<ProtectedStaffRoute element={BrandList} />}
            />

            <Route
              path="/qaapprover-dashboard"
              element={<ProtectedStaffRoute element={QAADashboard} />}
            />
            <Route
              path="/CM-Info"
              element={<ProtectedStaffRoute element={Profile} />}
            />
            <Route
              path="/declined-seller"
              element={<ProtectedStaffRoute element={DeclinedSeller} />}
            />
            <Route
              path="/CM-change-password"
              element={<ProtectedStaffRoute element={CmPassword} />}
            />
            <Route
              path="/manage-roles"
              element={<ProtectedRoute element={ManageRoles} />}
            />
            <Route
              path="/designation-list"
              element={<ProtectedRoute element={DesignationList} />}
            />
            <Route
              path="/qa-profile"
              element={<ProtectedStaffRoute element={QaProfile} />}
            />
            <Route
              path="/pending-seller"
              element={<ProtectedStaffRoute element={PendingSeller} />}
            />
            <Route
              path="/decline-seller"
              element={<ProtectedStaffRoute element={DeclineSellers} />}
            />
            <Route
              path="/change-password"
              element={<ProtectedStaffRoute element={Qapassword} />}
            />
            <Route
              path="/mm-dashboard"
              element={<ProtectedStaffRoute element={MMDash} />}
            />
            <Route
              path="/mh-dashboard"
              element={<ProtectedStaffRoute element={MHDash} />}
            />
            <Route
              path="/mh-profile"
              element={<ProtectedStaffRoute element={MHProfile} />}
            />
            <Route
              path="/mh-change-password"
              element={<ProtectedStaffRoute element={MHPassword} />}
            />
            <Route
              path="/mm-profile"
              element={<ProtectedStaffRoute element={MMProfile} />}
            />
            <Route
              path="/mm-change-password"
              element={<ProtectedStaffRoute element={MMPassword} />}
            />
            <Route
              path="/mh-assign-brands"
              element={<ProtectedStaffRoute element={MHAssignSeller} />}
            />
            <Route
              path="/MM-dashboard/associate-brand/:id"
              element={<ProtectedStaffRoute element={AssociateBrand} />}
            />
          </Routes>
        )}
      </PreserveLocation>
    </Router>
  );
};
export default MainRoutes;
